// @flow
import React from 'react';
import Layout from '../components/layout';
import Link from '../components/link';
import GlossaryLink from '../components/glossary-link';
import type { FrontMatter } from '../utils/types';
import { withFrontMatter } from '../components/front-matter';
import routes from '../routes';

type Props = {
  frontMatter: FrontMatter,
};

class Canyoneering_Step extends React.Component<Props> {
  render() {
    const { frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <p>
          The last person on rappel has a difficult job to do - even under the
          easiest of circumstances. They have to do everything the previous
          canyoneers have done – go through <GlossaryLink>CHECK</GlossaryLink>,
          safely rappel down to the bottom, and manage a bulky{' '}
          <GlossaryLink>backpack</GlossaryLink> they are bringing down with
          them. In addition, they also have to remember to remove any extra gear
          attached to the <GlossaryLink>anchor</GlossaryLink>, manage the{' '}
          <GlossaryLink>pull cord</GlossaryLink>, set the ropes to ensure an
          easy <GlossaryLink>pull</GlossaryLink>, and check that no equipment is
          accidentally left behind. And they have to do all of this without any
          other human checking their work for mistakes.
        </p>
        <h2>Real-life problems</h2>
        <blockquote>
          On the third rappel in{' '}
          <Link to="http://ropewiki.com/Behunin_Canyon">Behunin</Link> the last
          rappeller came down, started to pull the rope and realized it was
          stuck tight. What happened? He quickly realized he had forgotten to
          take the <GlossaryLink id="safety-rigging">safety</GlossaryLink> off
          and now had to <GlossaryLink>ascend</GlossaryLink> back up to fix it.
          That added an hour to our descent that day.
        </blockquote>
        <blockquote>
          At the first rap up{' '}
          <Link to="http://ropewiki.com/Elephant_Butte">Elephant Butte</Link> I
          was the last rappeller and was about to rig into the rap line when
          another group came over the fin right behind me. It startled me enough
          that I forgot to clip into the rope before starting my rappel. Luckily
          it was a shallow start and I caught myself before needing to fully
          weight it. Yikes!
        </blockquote>
        <blockquote>
          My first trip through{' '}
          <Link to="http://ropewiki.com/Medieval_Chamber">
            Medieval Chamber
          </Link>{' '}
          someone left their bag at the top of the Morning Glory Arch rappel. He
          hadn’t pulled the rope yet and we joked for a while about tying him
          back into the rappel line and the other eight canyoneers would all
          pull on the other end to get him back up.
        </blockquote>
        <blockquote>
          In{' '}
          <Link to="http://ropewiki.com/Leprechaun_Canyon_(East_Fork)">
            East Leprechaun
          </Link>{' '}
          at the first optional rappel right at the beginning of the canyon the
          rope has to travel down a tight and winding{' '}
          <GlossaryLink>flute</GlossaryLink> before reaching the ledge where the
          rope is actually needed. In ignorance I had rigged a{' '}
          <GlossaryLink>biner block</GlossaryLink>. Yep, it got stuck in the
          flute. I was able to <GlossaryLink>batman up</GlossaryLink>, remove
          the block, and <GlossaryLink>hand line</GlossaryLink> down so we could
          retrieve the rope. A little forethought would have prevented this from
          happening.
        </blockquote>
        <p>
          What does one do when there are so many things to remember in order to
          stay alive and successfully complete a rappel? Make an acronym, of
          course!
        </p>
        <h2>STEP Acronym</h2>
        <p>
          This should be the last step before taking that first step off the
          edge. This means run through <GlossaryLink>CHECK</GlossaryLink> and be
          ready to go down, then run through <GlossaryLink>STEP</GlossaryLink>.
        </p>
        <ul>
          <li>
            <b>Safety</b> – If I have a safety, such as a{' '}
            <GlossaryLink>canyon quickdraw</GlossaryLink> clipped into a{' '}
            <GlossaryLink>figure 8 on a bight</GlossaryLink> on the pull side of
            the rope, it’s time to remove it.
          </li>
          <li>
            <b>Tug</b> – Tug on the rope with your clipped-in rappel device to
            make sure you are on the rappel side of the rope and that the block
            is staying with the <GlossaryLink>rigging</GlossaryLink>.
          </li>
          <li>
            <b>Equipment</b> – Look around to make sure your group didn’t
            accidentally leave anything behind.
          </li>
          <li>
            <b>Pull</b> – Start the rappel and make sure the pull and rappel
            ropes are separated and positioned for an easy pull. More on this in{' '}
            <Link to={routes.page_pull}>The Pull article</Link>.
          </li>
        </ul>
        <h2>Limitations</h2>
        <p>
          <GlossaryLink>STEP</GlossaryLink> as defined now does not work well
          with <GlossaryLink>marginal anchors</GlossaryLink>, though I still say
          it to myself on all rappels anyway.
        </p>
        <p>
          If you screw up on <b>E - Equipment</b>, you'll likely have to unrig
          yourself, go get the item, and go through the entire process of CHECK
          and STEP again. Ideally one should check for equipment lying around
          before going through CHECK. It's very uncommon to leave stuff behind
          so it's never been a problem for me, but it is an imperfection with
          the acronym.
        </p>
        <p>
          Since this is my own acronym, I want to know your thoughts on how to
          improve it. It has gone through several iterations and this is what I
          have settled on.
        </p>
        <p>Is there anything that is missing?</p>
        <p>
          Is there a set of words that create a better acronym and still
          includes all of these steps in a good order?
        </p>
      </Layout>
    );
  }
}

export default withFrontMatter('step')(Canyoneering_Step);
